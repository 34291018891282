<template lang="pug">
.mobile-onboarding-industry.px-4.py-3(style="width: 100%")
  .brand-mobile-onboarding-title-sm {{ $t('mobileOnboarding.titles.industry') }}
  om-select-cards.w-100(v-model="selected" :options="businessOptions")
</template>

<script>
  import { steps, industries } from '@/utils/mobileOnboarding';
  import onboardingHelpers from '@/mixins/mobileOnboarding';
  import saveStep from '@/mixins/saveOnboardingProgress';
  import ButtonGroup from '../ButtonGroup.vue';

  export default {
    name: 'MobileOnboardingIndustry',
    components: { ButtonGroup },
    mixins: [onboardingHelpers, saveStep],
    data: () => ({ selected: null, steps, industries }),
    computed: {
      businessOptions() {
        return this.industries.map((industry) => ({
          icon: industry === 'saas' ? 'service' : industry,
          key: this.$t(`mobileOnboarding.industries.${industry}`),
          value: industry,
        }));
      },
    },
    watch: {
      selected(v) {
        if (v) this.next();
      },
    },
    methods: {
      validate() {
        return this.selected !== null;
      },
      getExtras() {
        return { query: { industry: this.selected } };
      },
    },
  };
</script>

<style lang="sass">
  @media screen and (max-width: 575px)
    .mobile-onboarding-industry
      .brand-select-card
        padding-left: 6.25rem !important
        .brand-svg-transition-bg
            margin-left: .625rem
</style>
